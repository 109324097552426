/**
 * Edit existing client.
 */
import { apiGet, apiPut, apiPost } from '@/util/api';

export default {
  name: 'ClientEdit',
  data() {
    return {
      loading: true,

      clients: [],
      client: null,

      testing: false,
      testCopy: undefined,
      testOutput: undefined,
      testLoading: false,

      audienceNames: [
        this.$t('audience_0'),
        this.$t('audience_1'),
        this.$t('audience_2'),
        this.$t('audience_3'),
        this.$t('audience_4'),
        this.$t('audience_5'),
      ],
    };
  },
  computed: {
    activeClient() {
      return this.clients.find(client => client.id === this.client);
    },
    activeClientIsDefault() {
      return !this.activeClient?.id;
    },
    activeVoice() {
      return this.activeClient?.voiceConfig || {};
    },
    fieldPlaceholder() {
      return this.activeClientIsDefault ? 'Not set' : 'Default';
    },
  },
  async created() {
    const clients = await apiGet('/client');
    const voiceConfigs = await apiGet('/voice');

    this.clients = [{ name: 'Default', id: null }, ...clients];

    this.clients.forEach(client => {
      const voiceConfig = voiceConfigs.find(
        voice => voice.clientId === client.id,
      );

      client.voiceConfig = {
        query: voiceConfig?.query || '',
        threshold: voiceConfig?.threshold,
        totalInvocations: parseInt(voiceConfig?.totalInvocations) || 0,
        voices: [
          ['', '', '', '', '', ''],
          ['', '', '', '', '', ''],
          ['', '', '', '', '', ''],
          ['', '', '', '', '', ''],
          ['', '', '', '', '', ''],
          ['', '', '', '', '', ''],
        ],
        voicesUsage: [
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0],
        ],
      };

      if (voiceConfig?.voices) {
        JSON.parse(voiceConfig.voices).forEach((row, i) => {
          row.forEach((voice, j) => {
            client.voiceConfig.voices[i][j] = voice;
          });
        });
      }

      if (voiceConfig?.voicesUsage) {
        JSON.parse(voiceConfig.voicesUsage).forEach((row, i) => {
          row.forEach((voice, j) => {
            client.voiceConfig.voicesUsage[i][j] = parseInt(voice) || 0;
          });
        });
      }
    });

    this.loading = false;
  },
  methods: {
    /**
     * Update the specified property on the client.
     * @param property
     */
    async save() {
      if (this.testing) return;

      const id = this.activeClient.id || 'default';

      const threshold = parseInt(this.activeVoice.threshold);
      const voiceData = {
        query: this.activeVoice.query,
        threshold: !isNaN(threshold) ? threshold : null,
        voices: JSON.stringify(this.activeVoice.voices),
      };

      await apiPut(`/voice/${id}`, voiceData)
        .then(() => {
          const message = this.activeClientIsDefault
            ? this.$t('configure_voices_success_default')
            : this.$t('configure_voices_success_client', this.activeClient);
          this.$bvToast.toast(message, {
            title: this.$t('common_success_title'),
            variant: 'success',
          });
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('edit_client_error'), {
            title: this.$t('common_error_title'),
            variant: 'danger',
          });
        });
    },
    async testVoice(voice) {
      this.testLoading = true;
      const { message } = await apiPost('/fubify/test', {
        prompt: this.activeVoice.query,
        voice,
        copy: this.testCopy,
      });
      this.testOutput = message;
      this.testLoading = false;
    },
  },
};
